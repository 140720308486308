import type { MouseEvent } from "react";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

export interface EmptyStateProps {
  header: string;
  description?: string;
  primaryLabel?: string;
  primaryDisabled?: boolean;
  onPrimaryClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  secondaryLabel?: string;
  secondaryDisabled?: boolean;
  onSecondaryClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  tertiaryLabel?: string;
  tertiaryDisabled?: boolean;
  onTertiaryClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const EmptyState = ({
  header,
  description,
  primaryLabel,
  primaryDisabled = false,
  onPrimaryClick,
  secondaryLabel,
  secondaryDisabled = false,
  onSecondaryClick,
  tertiaryLabel,
  tertiaryDisabled = false,
  onTertiaryClick,
}: EmptyStateProps) => {
  const hasPrimary = Boolean(onPrimaryClick && primaryLabel);
  const hasSecondary = Boolean(onSecondaryClick && secondaryLabel);
  const hasTertiary = Boolean(onTertiaryClick && tertiaryLabel);

  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center gap-9 p-8 text-center"
      data-testid="empty-state"
    >
      <div className="flex max-w-lg flex-col gap-3">
        <Typography.TitleMd>{header}</Typography.TitleMd>
        {description && (
          <Typography.BodyMd>
            <SanitizeHTMLString content={description} />
          </Typography.BodyMd>
        )}
      </div>
      <div className="flex gap-3">
        {hasPrimary && (
          <Button
            type="button"
            variant="circle"
            onClick={onPrimaryClick}
            disabled={primaryDisabled}
          >
            {primaryLabel}
          </Button>
        )}
        {hasSecondary && (
          <Button
            type="button"
            variant="secondary"
            onClick={onSecondaryClick}
            disabled={secondaryDisabled}
          >
            {secondaryLabel}
          </Button>
        )}
      </div>
      {hasTertiary && (
        <Button
          type="button"
          variant="link"
          onClick={onTertiaryClick}
          disabled={tertiaryDisabled}
        >
          {tertiaryLabel}
        </Button>
      )}
    </div>
  );
};
