import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { find, values } from "lodash";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import "./styles.scss";

export interface MultiStepPanelContextProps {
  steps: Record<string, any>;
  onNextClick: (event: MouseEvent, activeStep: number) => void;
  onPreviousClick: (sequence: number) => void;
}

export type MultiStepPanelContextValue = MultiStepPanelContextProps | undefined;

export const MultiStepPanelContext =
  createContext<MultiStepPanelContextValue>(undefined);
MultiStepPanelContext.displayName = "MultiStepPanelContext";

export interface MultiStepPanelProps {
  steps: Record<string, any>;
  onNextClick: (sequence: number) => void;
  onPreviousClick: (sequence: number) => void;
  onClose: () => void;
  stepPanelContainer: JSX.Element;
}

export const MultiStepPanel = ({
  steps,
  onNextClick,
  onPreviousClick,
  onClose,
  stepPanelContainer,
}: MultiStepPanelProps) => {
  const [activeStepData, setActiveStepData] = useState({ sequence: 1 });

  const handleNextButtonClick = useCallback(
    (event, activeStep) => {
      event.preventDefault();
      onNextClick(activeStep);
    },
    [onNextClick],
  );

  const handlePreviousButtonClick = useCallback(
    event => {
      event.preventDefault();
      onPreviousClick(activeStepData.sequence);
    },
    [onPreviousClick, activeStepData],
  );

  useEffect(() => {
    setActiveStepData(find(values(steps), { status: "active" }));
  }, [steps]);

  const value = useMemo(
    () => ({
      steps,
      onNextClick: handleNextButtonClick,
      onPreviousClick: handlePreviousButtonClick,
    }),
    [steps, handleNextButtonClick, handlePreviousButtonClick],
  );

  return (
    <MultiStepPanelContext.Provider value={value}>
      <div className="multi-step-panel">
        <div className="multi-step-panel__step-panel-container">
          <div
            className="multi-step-panel__navigation-panel-container__actions"
            data-testid="close-modal"
          >
            {activeStepData.sequence > 1 && (
              <button
                type="button"
                className="multi-step-panel__navigation-panel-container__topbar-button"
                onClick={handlePreviousButtonClick}
                aria-label={t("back")}
              >
                <Icon type="back-arrow-v2" />
              </button>
            )}
            {activeStepData.sequence === 1 && <span />}

            <button
              type="button"
              className="multi-step-panel__navigation-panel-container__topbar-button"
              onClick={onClose}
            >
              <Icon type="close-v2" />
            </button>
          </div>
          <div className="multi-step-panel__step-panel-container__wrapper">
            {stepPanelContainer}
          </div>
        </div>
      </div>
    </MultiStepPanelContext.Provider>
  );
};
