import { useCallback, useState } from "react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-shared/uikit/Typography";

export interface ContainerProps {
  title: string;
  subtitle?: string;
  collapsible?: boolean;
  defaultExpanded?: boolean;
  children: React.ReactNode;
  headerBackgroundColor?: string;
}

// TODO: add some smooth transition when expanding/collapsing
export const Container = ({
  title,
  subtitle,
  collapsible = true,
  defaultExpanded = false,
  children,
  headerBackgroundColor = "bg-secondary",
}: ContainerProps) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const toggle = useCallback(() => setIsExpanded(!isExpanded), [isExpanded]);

  return (
    <div className="border-primary flex flex-col rounded-lg border">
      <div
        className={classNames(
          "border-primary items-start rounded-t-lg px-4 py-2",
          {
            "rounded-b-lg": collapsible && !isExpanded,
            "border-b": !collapsible || isExpanded,
          },
          headerBackgroundColor,
        )}
      >
        <div className="flex flex-row justify-between align-top">
          <div>
            <Typography.LabelXxs weight="semibold">{title}</Typography.LabelXxs>
            {subtitle && <Typography.BodyXs>{subtitle}</Typography.BodyXs>}
          </div>
          <div>
            {collapsible && (
              <Button
                type="button"
                variant="tertiary"
                className="!p-0"
                onClick={toggle}
              >
                <Icon
                  type={isExpanded ? "16-chevron-down" : "16-chevron-right"}
                  size={16}
                  viewBox="0 0 20 20"
                />
              </Button>
            )}
          </div>
        </div>
      </div>
      {(!collapsible || isExpanded) && children}
    </div>
  );
};
