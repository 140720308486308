import classNames from "classnames";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { t } from "@/i18n-js/instance";
import { notificationActionType } from "@circle-react/components/Notifications/NotificationsList/List/Actions/ActionContent";
import { usePunditUserContext } from "@circle-react/contexts";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { useNotificationContext } from "../NotificationContext";
import { Item } from "./Item";
import { Actions } from "./Item/Actions";
import { ListWrapper } from "./ListWrapper";
import { NoNotifications } from "./NoNotifications";
import { NotificationItemWrapper } from "./NotificationItemWrapper";
import { SkeletonLoader } from "./SkeletonLoader";
import { TimeStamp } from "./TimeStamp";
import { UserImageWithIcon } from "./UserImageWithIcon";

export const NotificationsList = () => {
  const { isLoading, notifications, fetchNextPage, hasNextPage } =
    useNotificationContext();

  const { currentCommunity } = usePunditUserContext();
  const current_plan_tier = currentCommunity?.current_plan_tier;

  if (isLoading)
    return (
      <ListWrapper>
        <SkeletonLoader rows={15} />
      </ListWrapper>
    );

  if (
    !notifications ||
    !notifications.pages[0] ||
    isEmpty(notifications.pages[0].records)
  ) {
    return <NoNotifications />;
  }

  return (
    <ListWrapper>
      <InfiniteScroll
        style={{
          overflow: "auto",
        }}
        scrollThreshold={0.8}
        next={fetchNextPage}
        hasMore={hasNextPage}
        dataLength={notifications.pages.length}
        scrollableTarget="user-notifications-panel-header"
        loader={<Loader center />}
      >
        {notifications.pages.map(page =>
          page.records.map(notification => (
            <div
              className="hover:bg-tertiary focus-visible:bg-tertiary group flex w-full items-start justify-between rounded-md px-4 py-3 transition-colors duration-200 ease-in-out"
              key={notification.id}
            >
              <NotificationItemWrapper notification={notification}>
                <div
                  className={classNames(
                    "flex items-start",
                    notificationActionType(notification),
                  )}
                >
                  <UserImageWithIcon
                    src={notification.actor_image || ""}
                    name={notification.actor_name}
                    action={notification.action}
                  />
                  <div
                    className="flex w-full flex-col items-start space-y-1"
                    data-testid="notification-details"
                  >
                    <Item notification={notification} />
                    <TimeStamp createdAt={notification.created_at} />
                  </div>
                </div>
              </NotificationItemWrapper>
              <div>
                {!notification.read_at ? (
                  <div className="flex h-6 w-6 items-center justify-center group-hover:hidden">
                    <span className="bg-messaging-info-blue inline-block h-2 w-2 rounded-full group-hover:hidden" />
                  </div>
                ) : (
                  <div className="flex h-6 w-6 group-hover:hidden" />
                )}
                <Actions notification={notification} />
              </div>
            </div>
          )),
        )}
      </InfiniteScroll>
      {current_plan_tier && (
        <div className="w-full py-2 text-center">
          <Typography.LabelXs>
            {t("notification_retention_message", {
              months: t(`notification_retention_months.${current_plan_tier}`),
            })}
          </Typography.LabelXs>
        </div>
      )}
    </ListWrapper>
  );
};
