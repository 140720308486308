import { Portal } from "@material-ui/core";
import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import type { IconType } from "@circle-react-shared/Icon/IconNames";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { useAddTipTapLinkModal } from "@circle-react-uikit/TipTapBlockEditor/AddTipTapLinkModal";
import { getCurrentRange } from "@circle-react-uikit/TipTapBlockEditor/Toolbar/helpers";

interface Option {
  name: string;
  icon: IconType;
  ariaLabel: string;
  iconSize?: number;
  activeFnOptions?: object;
  onClick?: () => any;
}

export const FullToolbar = ({
  editor,
  portalId,
  showImagePickerModal,
  showFileUploadModal,
  showGiphyPickerModal,
}: any) => {
  const tiptapLinkModal = useAddTipTapLinkModal();

  if (!editor) {
    return null;
  }
  const options: Option[] = [
    {
      name: "bold",
      icon: "20-character-bold",
      onClick: () => editor.chain().focus().toggleBold().run(),
      ariaLabel: t("tiptap.bubble_menu.bold"),
    },
    {
      name: "italic",
      icon: "20-character-italic",
      onClick: () => editor.chain().focus().toggleItalic().run(),
      ariaLabel: t("tiptap.bubble_menu.italic"),
    },
    {
      name: "underline",
      icon: "20-character-underline",
      onClick: () => editor.chain().focus().toggleUnderline().run(),
      ariaLabel: t("tiptap.bubble_menu.underline"),
    },
    {
      name: "strike",
      icon: "20-character-strike",
      onClick: () => editor.chain().focus().toggleStrike().run(),
      ariaLabel: t("tiptap.bubble_menu.strike"),
    },
    {
      name: "separator",
      icon: "32-line-vertical",
      ariaLabel: t("tiptap.separator"),
    },
    {
      name: "link",
      icon: "20-character-link",
      onClick: () => tiptapLinkModal.show({ editor: editor }),
      ariaLabel: t("tiptap.link"),
    },
    {
      name: "attachment",
      icon: "20-attach",
      onClick: () => showFileUploadModal({ editor: editor }),
      ariaLabel: t("tiptap.attach_file"),
    },
    {
      name: "image",
      icon: "20-file-image",
      onClick: () =>
        showImagePickerModal({ editor, range: getCurrentRange({ editor }) }),
      ariaLabel: t("tiptap.add_image"),
    },
    {
      name: "gif",
      icon: "20-gif",
      onClick: () =>
        showGiphyPickerModal({ editor, range: getCurrentRange({ editor }) }),
      ariaLabel: t("tiptap.add_gif"),
    },
    {
      name: "emoji",
      icon: "16-editor-emoji",
      iconSize: 16,
      onClick: () =>
        editor
          .chain()
          .focus()
          .deleteRange(getCurrentRange({ editor }))
          .insertContent(":")
          .run(),
      ariaLabel: t("tiptap.add_emoji"),
    },
    {
      name: "separator",
      icon: "32-line-vertical",
      ariaLabel: t("tiptap.separator"),
    },
    {
      name: "heading",
      icon: "20-character-h2",
      onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      activeFnOptions: { level: 2 },
      ariaLabel: t("tiptap.bubble_menu.h2"),
    },
    {
      name: "heading",
      icon: "20-character-h3",
      onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
      activeFnOptions: { level: 3 },
      ariaLabel: t("tiptap.bubble_menu.h3"),
    },
    {
      name: "orderedList",
      icon: "16-editor-ol",
      iconSize: 16,
      onClick: () =>
        editor
          .chain()
          .focus()
          .deleteRange(getCurrentRange({ editor }))
          .toggleOrderedList()
          .run(),
      ariaLabel: t("tiptap.ordered_list"),
    },
    {
      name: "bulletList",
      icon: "16-editor-ul",
      iconSize: 16,
      onClick: () =>
        editor
          .chain()
          .focus()
          .deleteRange(getCurrentRange({ editor }))
          .toggleBulletList()
          .run(),
      ariaLabel: t("tiptap.bullet_list"),
    },
  ];

  return (
    <Portal container={() => document.getElementById(portalId)}>
      <div className="border-b-primary flex items-center space-x-1.5 border-b px-8 py-2.5">
        {options.map(option =>
          option.name === "separator" ? (
            <div key={option.name} className="border-primary h-6 border-r" />
          ) : (
            <IconButton
              key={option.name}
              name={option.icon}
              iconSize={option.iconSize ?? 20}
              iconClassName="!text-dark"
              className={classnames("rounded border", {
                "bg-tertiary text-dark border-light": editor.isActive(
                  option.name,
                  option.activeFnOptions ?? {},
                ),
                "border-transparent": !editor.isActive(option.name),
              })}
              onClick={option.onClick}
              ariaLabel={option.ariaLabel}
            />
          ),
        )}
      </div>
    </Portal>
  );
};
