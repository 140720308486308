import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import type { BookmarkType } from "@circle-react/types/Bookmarks";

interface NoBookmarksProps {
  activeTab: BookmarkType;
}

export const NoBookmarks = ({ activeTab }: NoBookmarksProps) => (
  <div className="mt-2 flex h-[calc(100%-100px)] w-full flex-col items-center justify-center space-y-2 px-6 pb-2 text-center">
    <Typography.TitleMd>
      {t(`header_v3.no_bookmarks_by_type.${activeTab}`)}
    </Typography.TitleMd>
    <Typography.BodyMd>
      {t(`header_v3.no_bookmarks_description_by_type.${activeTab}`)}
    </Typography.BodyMd>
  </div>
);
