import type { ReactNode } from "react";
import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import "./styles.scss";

interface CollapsibleProps {
  title: ReactNode;
  children: ReactNode;
  noPadding?: boolean;
  noBorder?: boolean;
  defaultOpen?: boolean;
}

export const Collapsible = ({
  title,
  children,
  noPadding,
  noBorder,
  defaultOpen = false,
}: CollapsibleProps) => (
  <Disclosure defaultOpen={defaultOpen}>
    {({ open }) => (
      <div
        className={classNames("collapsible", {
          "collapsible--no-border": noBorder || !open,
        })}
      >
        <Disclosure.Button
          className={classNames(
            "collapsible__title border-primary items-center",
            {
              "collapsible__title--no-padding": noPadding,
              "border-b": !open,
            },
          )}
        >
          <span className="text-dark text-base font-semibold">{title}</span>
          <span className="flex h-6 w-6 items-center justify-center">
            <Icon
              type="16-chevron-down"
              size={16}
              className={classNames("collapsible__arrow text-dark", {
                "collapsible__arrow--open": open,
              })}
            />
          </span>
        </Disclosure.Button>
        <Disclosure.Panel className="collapsible__children" unmount={false}>
          {children}
        </Disclosure.Panel>
      </div>
    )}
  </Disclosure>
);
