import { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useFetchHeaderNavItems } from "@circle-react/components/SettingsApp/Navigation/hooks/useFetchHeaderNavItems";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { HEADER_V3_ROUTES } from "../constants";

export const AdminOnlyRouteNotice = () => {
  const { currentCommunity, currentCommunityMember } = usePunditUserContext();
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { data: headerNavItems, isLoading } = useFetchHeaderNavItems({
    id: currentCommunity?.id,
  });

  const { isAdminOnlyRoute, matchedLinkId, isCircleDirectoryLink } =
    useMemo(() => {
      if (!headerNavItems) return { isAdminOnlyRoute: false, matchedLinkId: 0 };

      const matchedItem = headerNavItems.find(item => {
        if (item.visibility !== "admin_only") return false;
        if (item.kind === "custom") return item.url === location.pathname;
        return HEADER_V3_ROUTES[item.kind] === location.pathname;
      });

      return {
        isAdminOnlyRoute: !!matchedItem,
        matchedLinkId: matchedItem ? matchedItem.id : null,
        isCircleDirectoryLink: matchedItem?.kind !== "custom",
      };
    }, [headerNavItems, location.pathname]);

  useEffect(() => {
    let timer: any = null;
    if (isAdminOnlyRoute) {
      timer = setTimeout(() => setIsVisible(true), 100);
    } else {
      setIsVisible(false);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isAdminOnlyRoute]);

  const handleNavigateToSettings = useCallback(() => {
    history.push(
      `/settings/navigation?selectedLinkId=${matchedLinkId}&isCircleDirectoryLink=${isCircleDirectoryLink}`,
    );
  }, [history, matchedLinkId, isCircleDirectoryLink]);

  if (
    !isAdminOnlyRoute ||
    isLoading ||
    !isCommunityAdmin(currentCommunityMember)
  )
    return null;

  return (
    <div
      className={classNames(
        "bg-dark-primary-button",
        "absolute bottom-6 left-1/2 -translate-x-1/2",
        "h-12 rounded-full px-7 py-3",
        "transition-all duration-150 ease-out",
        "flex items-center justify-center",
        "z-50 min-w-[25rem]",
        {
          "translate-y-0 opacity-100": isVisible,
          "translate-y-full opacity-0": !isVisible,
        },
      )}
    >
      <Typography.LabelSm weight="medium" color="text-badge">
        {t("settings.navigation.admin_only_route_floating_bar.description")}{" "}
        <Button
          variant="link"
          className="!text-link !p-0 !font-medium"
          onClick={handleNavigateToSettings}
        >
          {t("settings.navigation.admin_only_route_floating_bar.cta_text")}
        </Button>
      </Typography.LabelSm>
    </div>
  );
};
