import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  isCommunityAdmin,
  isCommunityModerator,
} from "@circle-react/helpers/communityMemberHelpers";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { ShortcutDisplay } from "./ShortcutDisplay";
import { KEYBOARD_SHORTCUTS_DATA } from "./constants";

export const KeyboardShortcutsModalComponent = () => {
  const modal = useModal();
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const isModerator = isCommunityModerator(currentCommunityMember);

  return (
    <Modal isOpen={modal.visible} onClose={modal.visible ? modal.hide : noop}>
      <Modal.Overlay />
      <Modal.Content size="lg">
        <Modal.Header>
          <Modal.Title className="text-center" size="md">
            {t("header_v3.keyboard_shortcuts")}
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.visible ? modal.hide : noop} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-5">
            {KEYBOARD_SHORTCUTS_DATA.map(data => (
              <div key={data.category} className="flex flex-col">
                <Typography.LabelMd
                  color="text-selector-active"
                  weight="semibold"
                >
                  {data.category}
                </Typography.LabelMd>
                {data.shortcuts.map(shortcut => (
                  <ShortcutDisplay
                    key={shortcut.name}
                    shortcut={shortcut}
                    isAdmin={isAdmin}
                    isModerator={isModerator}
                  />
                ))}
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const KeyboardShortcutsModal = ModalManager.create(() => (
  <KeyboardShortcutsModalComponent />
));

export const useKeyboardShortcutsModal = () => useModal(KeyboardShortcutsModal);
