import { noop } from "lodash";
import { Link } from "react-router-dom";
import { getRouteWithPrevLocation } from "@/react/helpers/urlHelpers";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import { chatRoomPath } from "@circle-react/helpers/urlHelpers";

interface ItemWrapperProps {
  children: React.ReactNode;
  onClose: () => void;
  uuid: string;
}

export const ItemWrapper = ({
  children,
  onClose = noop,
  uuid,
}: ItemWrapperProps) => (
  <div className="border-secondary-dark mx-2 border-b last:border-none">
    <Link
      className="hover:bg-tertiary focus-visible:bg-tertiary -mx-2 flex cursor-pointer items-start justify-between rounded-md px-4 py-3 transition-colors duration-200 ease-in-out"
      onClick={e => {
        if (!isIntendedOnNewTab(e)) {
          onClose();
        }
      }}
      to={getRouteWithPrevLocation({ url: chatRoomPath({ uuid }) })}
      data-testid="chat-room-dm-list"
    >
      {children}
    </Link>
  </div>
);
