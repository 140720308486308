import { useMemo } from "react";
import { usePunditUserContext, useSpacesContext } from "@circle-react/contexts";
import {
  isAllEventsDirectoryVisible,
  isCommunityAdmin,
  isCoursesDirectoryVisible,
  isLeaderboardVisible,
  isMemberDirectoryVisible,
} from "@circle-react/helpers/communityMemberHelpers";
import { communitiesPaths } from "@circle-react/helpers/routerPathHelpers";
import {
  useExtraLargePlusScreenMediaQuery,
  useExtraLargeScreenMediaQuery,
  useThreeExtraLargeScreenMediaQuery,
  useTwoExtraLargeScreenMediaQuery,
} from "@circle-react/hooks/useMediaQuery";
import type { Space } from "@circle-react/types";

const MAX_VISIBLE_LINKS = {
  lg: 2,
  xl: 3,
  xlPlus: 4,
  "2xl": 5,
  "3xl": 6,
} as const;

export const useNavigationBar = () => {
  const { currentCommunity, currentCommunitySettings, currentCommunityMember } =
    usePunditUserContext();
  const isAdmin = Boolean(isCommunityAdmin(currentCommunityMember));
  const { helpers } = useSpacesContext();
  const { spaceVisibleOnSidebar, isLoading: isLoadingSpaces } = helpers;
  const isHomePageEnabled = Boolean(currentCommunity?.home_page_enabled);

  const { default_existing_member_space_id: defaultExistingMemberSpaceId } =
    currentCommunity || {};

  const shouldShowMembersLink = isMemberDirectoryVisible(
    currentCommunityMember,
    currentCommunitySettings,
  );

  const shouldShowAllEventsLink = isAllEventsDirectoryVisible(
    currentCommunityMember,
    currentCommunitySettings,
  );

  const shouldShowCoursesLink = isCoursesDirectoryVisible(
    currentCommunityMember,
    currentCommunity,
  );

  const shouldShowLeaderboardLink = isLeaderboardVisible(currentCommunity);

  const hasEventsCoursesOrMembers =
    shouldShowAllEventsLink || shouldShowCoursesLink || shouldShowMembersLink;

  const shouldShowHomeLink = isAdmin || hasEventsCoursesOrMembers;

  const isXlScreen = useExtraLargeScreenMediaQuery();
  const isXlPlusScreen = useExtraLargePlusScreenMediaQuery();
  const is2XlScreen = useTwoExtraLargeScreenMediaQuery();
  const is3XlScreen = useThreeExtraLargeScreenMediaQuery();

  const maxVisibleLinks = useMemo(() => {
    if (is3XlScreen) {
      return MAX_VISIBLE_LINKS["3xl"];
    }
    if (is2XlScreen) {
      return MAX_VISIBLE_LINKS["2xl"];
    }
    if (isXlPlusScreen) {
      return MAX_VISIBLE_LINKS.xlPlus;
    }
    if (isXlScreen) {
      return MAX_VISIBLE_LINKS.xl;
    }
    return MAX_VISIBLE_LINKS.lg;
  }, [isXlScreen, is2XlScreen, isXlPlusScreen, is3XlScreen]);

  const homeLinkTo = useMemo(() => {
    const spaces: Space[] = spaceVisibleOnSidebar();

    const redirectToRootPathOfCommunity = () => {
      if (isHomePageEnabled) {
        return communitiesPaths.feed();
      }
      return "/";
    };

    if (isLoadingSpaces || defaultExistingMemberSpaceId === 0 || !spaces) {
      return redirectToRootPathOfCommunity();
    }

    const defaultSpace = spaces.find(
      (space: Space) => space.id === defaultExistingMemberSpaceId,
    );

    if (defaultSpace?.slug) {
      return `/c/${defaultSpace.slug}`;
    }

    if (isHomePageEnabled || spaces.length > 0) {
      return communitiesPaths.feed();
    }

    return "/";
  }, [
    defaultExistingMemberSpaceId,
    isLoadingSpaces,
    spaceVisibleOnSidebar,
    isHomePageEnabled,
  ]);

  return {
    homeLinkTo,
    shouldShowHomeLink,
    shouldShowMembersLink,
    shouldShowAllEventsLink,
    shouldShowCoursesLink,
    shouldShowLeaderboardLink,
    maxVisibleLinks,
  };
};
