import { t } from "@/i18n-js/instance";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import type { CommentBookmarkRecord } from "@circle-react/types/Bookmarks";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

export interface CommentProps {
  bookmark: CommentBookmarkRecord;
}

export const Comment = ({ bookmark }: CommentProps) => {
  const {
    created_at,
    title,
    author_image: authorImage,
    author_name: authorName,
    plain_text_body: plainTextBody,
    attachment_count: attachmentCount,
  } = bookmark.bookmark_record ?? {};

  const createdAtString = formattedDateTime({
    dateTime: created_at ? new Date(created_at) : new Date(),
    format: "long_date",
  });

  const hasAttachments = Boolean(attachmentCount && attachmentCount > 0);

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="py-1">
        <Typography.LabelXs weight="semibold">
          <span className="line-clamp-1">{title}</span>
        </Typography.LabelXs>
      </div>

      <div className="flex items-start gap-3">
        <UserImage src={authorImage ?? ""} name={authorName ?? ""} size="10" />

        <div className="flex flex-col gap-1">
          <div className="flex items-baseline gap-2">
            <Typography.LabelSm weight="semibold">
              {authorName}
            </Typography.LabelSm>
            <Typography.LabelXs color="text-default">
              {createdAtString}
            </Typography.LabelXs>
          </div>

          {plainTextBody && plainTextBody.trim() !== "" ? (
            <Typography.LabelSm color="text-default">
              <span className="line-clamp-2 leading-5">{plainTextBody}</span>
            </Typography.LabelSm>
          ) : (
            hasAttachments && (
              <Typography.LabelSm color="text-default">
                {t("bookmarks.attachments_count", {
                  count: attachmentCount,
                })}
              </Typography.LabelSm>
            )
          )}
        </div>
      </div>
    </div>
  );
};
