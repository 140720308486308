import type { ComponentPropsWithRef } from "react";
import classnames from "classnames";
import { mediaTranscriptPaths } from "@circle-react/helpers/urlHelpers";

export interface HtmlVideoPlayerProps extends ComponentPropsWithRef<"video"> {
  src: string;
  type: string;
  poster?: string;
  rounded?: boolean;
  isInset?: boolean;
  mediaTranscriptId?: number;
  preload?: "none" | "metadata" | "auto";
  controlsList?: string;
  autoPlay?: boolean;
  description?: string;
  className?: string;
}

export const HtmlVideoPlayer = ({
  src,
  type,
  poster,
  rounded = true,
  isInset = false,
  mediaTranscriptId,
  preload = "metadata",
  controlsList = "nodownload",
  autoPlay = false,
  description = "",
  className,
  ...rest
}: HtmlVideoPlayerProps) => (
  <video
    className={classnames(className, {
      "rounded-md": rounded && !isInset,
      "h-full w-full bg-black": isInset,
    })}
    controls
    controlsList={controlsList}
    preload={preload}
    poster={poster}
    autoPlay={autoPlay}
    {...rest}
  >
    <source src={src} type={type} />
    {description && <p className="sr-only">{description}</p>}
    {mediaTranscriptId && (
      <track
        kind="captions"
        src={mediaTranscriptPaths.showVtt({ id: String(mediaTranscriptId) })}
      />
    )}
  </video>
);
