import type { PopperOptions } from "@circle-react-uikit/Dropdown";

export const POPPER_OPTIONS: PopperOptions = {
  strategy: "fixed",
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, -1],
      },
    },
  ],
};
