import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";

export const Header = () => (
  <div className="flex items-center justify-between px-6 pb-2 pt-5">
    <Typography.LabelLg weight="medium">
      {t("header_v3.bookmarks")}
    </Typography.LabelLg>
  </div>
);
