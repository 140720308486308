import { ThreeUserRow } from "@circleco/ui-kit";
import { LabelXs } from "@/react/components/shared/uikit/Typography/components/Label/LabelXs";
import { toLocaleString } from "@circle-react/helpers/number";

export interface Member {
  id: string;
  name: string;
  avatar_url: string;
}

interface User {
  id: string;
  name: string;
  src: string;
}

export interface MembersAvatarRowProps {
  members?: Member[];
  amountToShow?: number;
  totalCount?: number;
}

export const MembersAvatarRow = ({
  members = [],
  amountToShow = 3,
  totalCount = 0,
}: MembersAvatarRowProps) => {
  const users: User[] = members
    .slice(0, amountToShow)
    .map((member: Member): User => {
      const name = member?.name || "";
      const src = member?.avatar_url || "";
      const id = member?.id || "";
      return { id, name, src };
    });

  if (users.length === 0) {
    return null;
  }

  const plusCount = totalCount - users.length;
  const hasPlusCount = plusCount > 0;

  return (
    <div className="isolate flex items-center gap-2 rounded-lg px-5 py-2 md:justify-center md:p-0">
      <div className="border-tertiary">
        <ThreeUserRow users={users} isFullRounded />
      </div>
      {hasPlusCount && (
        <div className="flex">
          <LabelXs weight="semibold" color="text-default">
            +{toLocaleString(plusCount)}
          </LabelXs>
        </div>
      )}
    </div>
  );
};
