import type { ReactNode } from "react";
import { noop } from "lodash";
import { Link, useLocation } from "react-router-dom";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import type { Notification } from "@circle-react/types/Notification";
import { usePopoverPortalContext } from "@circle-react-shared/uikit/PopoverPortal";
import { useNotificationsActions } from "../useHeaderNotificationsApi";
import { NOTIFICATION_ACTION_MAPPER } from "./Item/constants";

export interface NotificationItemWrapperProps {
  children: ReactNode;
  notification: Notification;
}

export const NotificationItemWrapper = ({
  children,
  notification,
}: NotificationItemWrapperProps) => {
  const { onClose = noop } = usePopoverPortalContext() || {};
  const { mutate: performNotificationAction } = useNotificationsActions();
  const { pathname } = useLocation();

  const onClickHandler = (event: any) => {
    performNotificationAction({
      id: notification.id,
      action: NOTIFICATION_ACTION_MAPPER.markAsRead,
    });
    if (isIntendedOnNewTab(event)) {
      return;
    }
    onClose();
  };

  const isOnSamePage = notification.action_web_path?.includes(pathname);
  if (isOnSamePage) {
    return (
      <a
        href={notification.action_web_path}
        onClick={onClickHandler}
        className="w-full"
      >
        {children}
      </a>
    );
  }
  return (
    <Link
      to={notification.action_web_path}
      onClick={onClickHandler}
      className="w-full"
    >
      {children}
    </Link>
  );
};
