import { useRef } from "react";
import classNames from "classnames";
import { useHotkeys } from "react-hotkeys-hook";
import { t } from "@/i18n-js/instance";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { BadgeIndicator } from "@circle-react-shared/uikit/HeaderV3/RightActionBlock/BadgeIndicator";

export interface NotificationPopoverButtonProps {
  notificationCount: number;
  isOpen?: boolean;
}

export const NotificationPopoverButton = ({
  notificationCount,
  isOpen = false,
}: NotificationPopoverButtonProps) => {
  const notificationsButtonRef = useRef<HTMLButtonElement | null>(null);

  useHotkeys(
    "alt+n",
    () => {
      notificationsButtonRef?.current?.click();
    },
    [notificationsButtonRef],
  );

  return (
    <div className="relative">
      <TippyV2 interactive={false} content={t("header_v3.notifications")}>
        <IconButton
          name="20-bell-v3"
          buttonSize={36}
          iconSize={20}
          className={classNames(
            "hover:!bg-c-header-hover focus:!bg-c-header-hover !text-c-header hover:!text-c-header-active focus:!text-c-header-active !rounded-md p-1.5 duration-150",
            {
              "!bg-c-header-active !text-c-header-active": isOpen,
            },
          )}
          iconClassName="!text-inherit !transition-none"
          ariaLabel={t("header_v3.notifications")}
          dataTestId="notifications-menu-popover-button"
          ref={notificationsButtonRef}
        />
        {notificationCount > 0 && (
          <BadgeIndicator
            count={notificationCount}
            dataTestId="unread-notifications-count"
          />
        )}
      </TippyV2>
    </div>
  );
};
