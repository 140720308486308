import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { useNotificationContext } from "../NotificationContext";

export const NoNotifications = () => {
  const { activeTab } = useNotificationContext();

  return (
    // h-[calc(100%-100px)] is the height of the list minus the height of the header, tabs and margin.
    <div className="mt-2 flex h-[calc(100%-100px)] w-full flex-col items-center justify-center space-y-2 px-6 pb-2 text-center">
      <Typography.TitleMd>
        {t(`header_v3.no_notifications.${activeTab || "inbox"}`)}
      </Typography.TitleMd>
      <Typography.BodyMd>
        {t(`header_v3.no_notifications_description.${activeTab || "inbox"}`)}
      </Typography.BodyMd>
    </div>
  );
};
