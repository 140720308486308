import React from "react";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface ComposedItem {
  label: string;
  items: string[];
}

export interface BulletedListProps {
  items?: string[];
  composedItems?: ComposedItem[];
}

export const BulletedList = ({ items, composedItems }: BulletedListProps) => (
  <div className="p-4">
    {composedItems ? (
      composedItems.map(composedItem => (
        <React.Fragment key={composedItem.label}>
          <Typography.BodySm>{composedItem.label}</Typography.BodySm>
          <ul className="list-outside list-disc pb-1 pl-5 marker:text-blue-500">
            {composedItem.items.map(item => (
              <li key={item} className="pb-1">
                <Typography.BodySm>{item}</Typography.BodySm>
              </li>
            ))}
          </ul>
        </React.Fragment>
      ))
    ) : (
      <ul className="list-outside list-disc pl-5">
        {items?.map(item => (
          <li key={item}>
            <Typography.BodySm>{item}</Typography.BodySm>
          </li>
        ))}
      </ul>
    )}
  </div>
);
