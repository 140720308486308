import type { ReactNode } from "react";
import { noop } from "lodash";
import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import type {
  BaseBookmarkRecord,
  BookmarkType,
} from "@circle-react/types/Bookmarks";
import { Icon } from "@circle-react-shared/Icon";
import { usePopoverPortalContext } from "@circle-react-shared/uikit/PopoverPortal";
import { Typography } from "@circle-react-uikit/Typography";

interface BookmarkItemWrapperProps {
  children: ReactNode;
  bookmark: BaseBookmarkRecord;
  bookmarkType: BookmarkType;
}

const TYPES = {
  post: t("bookmarks.singular.post"),
  comment: t("bookmarks.singular.comment"),
  event: t("bookmarks.singular.event"),
  lesson: t("bookmarks.singular.lesson"),
  message: t("bookmarks.singular.message"),
};

export const BookmarkItemWrapper = ({
  children,
  bookmark,
  bookmarkType,
}: BookmarkItemWrapperProps) => {
  const { onClose = noop }: { onClose?: () => void } =
    usePopoverPortalContext() ?? {};
  if (bookmark.bookmark_record === null && bookmark.is_deleted) {
    return (
      <div className="bg-tertiary flex w-full items-center rounded-md px-2 py-3">
        <Icon type="16-trash-bin" size={16} className="mr-1" />
        <Typography.LabelSm weight="medium" color="text-default">
          {t(`bookmarks.errors.content_removed`, {
            type: TYPES[bookmarkType],
          })}
        </Typography.LabelSm>
      </div>
    );
  }

  return (
    <Link
      to={bookmark.bookmark_record?.show_url || ""}
      className="w-full"
      onClick={event => {
        if (isIntendedOnNewTab(event)) {
          return;
        }
        onClose();
      }}
    >
      {children}
    </Link>
  );
};
