import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import type { CommunityMember } from "@circle-react/types";
import { Icon } from "@circle-react-shared/Icon";

interface Sender {
  community_member_id: number;
  name: string;
}

interface RichTextBody {
  circle_ios_fallback_text: string;
}

interface LastMessage {
  sender: Sender;
  body?: string;
  rich_text_body?: RichTextBody;
}

interface BodyProps {
  isDirect: boolean;
  chatRoomName: string;
  timestamp: string;
  lastMessage: LastMessage;
  currentCommunityMember: CommunityMember;
  isPinned?: boolean;
}

export const Body = ({
  isDirect,
  chatRoomName,
  timestamp,
  lastMessage,
  currentCommunityMember,
  isPinned,
}: BodyProps) => {
  const getMessageSenderName = () => {
    if (isDirect) return "";

    if (lastMessage.sender.community_member_id === currentCommunityMember?.id) {
      return `${t("messaging.you")}: `;
    }

    return `${lastMessage.sender.name}: `;
  };

  const renderMessageBody = () => {
    if (lastMessage.body) {
      return lastMessage.body;
    }

    return lastMessage.rich_text_body?.circle_ios_fallback_text;
  };

  return (
    <div>
      <div className="flex items-center space-x-1.5">
        {isPinned && (
          <Icon
            type="14-pin"
            aria-label={t("messaging.pinned_message")}
            aria-hidden={false}
            size={14}
            className="text-darkest !w-3.5"
          />
        )}
        <div className="max-w-[200px]">
          <Typography.BodySm truncate weight="font-semibold">
            {chatRoomName}
          </Typography.BodySm>
        </div>
        <Typography.BodySm as="time" weight="font-medium" color="text-light">
          {timestamp}
        </Typography.BodySm>
      </div>
      <div className="mt-0.5">
        <p className="text-default !line-clamp-1 flex max-w-[400px] items-center overflow-hidden hyphens-auto break-words text-sm">
          {`${getMessageSenderName()}${renderMessageBody()}`}
        </p>
      </div>
    </div>
  );
};
