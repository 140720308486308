import { useRef } from "react";
import classNames from "classnames";
import { useHotkeys } from "react-hotkeys-hook";
import { t } from "@/i18n-js/instance";
import { useUnreadChatThreads } from "@circle-react/hooks/chatsV2";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "../../IconButton";
import { BadgeIndicator } from "../BadgeIndicator";

export interface ChatThreadsPopoverButtonProps {
  isOpen?: boolean;
}

export const ChatThreadsPopoverButton = ({ isOpen = false }) => {
  const chatThreadsButtonRef = useRef<HTMLButtonElement>(null);
  const { unreadThreadsCount }: any = useUnreadChatThreads();

  useHotkeys(
    "alt+t",
    () => {
      chatThreadsButtonRef.current?.click();
    },
    [chatThreadsButtonRef],
  );

  return (
    <div className="relative">
      <TippyV2 interactive={false} content={t("header_v3.chat_threads")}>
        <IconButton
          name="20-thread-v3"
          buttonSize={36}
          iconSize={20}
          className={classNames(
            "hover:!bg-c-header-hover focus:!bg-c-header-hover !text-c-header hover:!text-c-header-active focus:!text-c-header-active !rounded-md p-1.5 duration-150",
            {
              "!bg-c-header-active !text-c-header-active": isOpen,
            },
          )}
          iconClassName="!text-inherit !transition-none"
          ariaLabel={t("header_v3.chat_threads")}
          dataTestId="chat-threads-popover-button"
          ref={chatThreadsButtonRef}
        />
        {unreadThreadsCount > 0 && (
          <BadgeIndicator count={unreadThreadsCount} />
        )}
      </TippyV2>
    </div>
  );
};
