import type { ReactNode } from "react";
import classNames from "classnames";

export interface TableWrapperProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const TableWrapper = ({ children, ...props }: TableWrapperProps) => (
  <div className={classNames("px-6 lg:px-9")} {...props}>
    {children}
  </div>
);
