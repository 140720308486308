import { useState } from "react";
import { capitalize } from "lodash";
import { t } from "@/i18n-js/instance";
import type { BookmarkType } from "@circle-react/types/Bookmarks";
import { Tab } from "@circle-react-uikit/TabV2";
import { BookmarksList } from "./List";

const TABS = [
  { value: "post", label: t("bookmarks.plural.posts") },
  { value: "comment", label: t("bookmarks.plural.comments") },
  { value: "event", label: t("bookmarks.plural.events") },
  { value: "lesson", label: t("bookmarks.plural.lessons") },
  { value: "message", label: t("bookmarks.plural.messages") },
] as const;

export const BookmarkContents = () => {
  const [activeTab, setActiveTab] = useState<BookmarkType>("post");

  const selectedTab = (tab: string) => TABS.findIndex(t => t.value === tab);

  return (
    <Tab.Group
      className="overflow-hidden"
      selectedIndex={selectedTab(activeTab)}
    >
      <div className="border-primary border-b px-6">
        <Tab.List>
          {TABS.map(tab => (
            <Tab
              key={tab.value}
              className="text-sm"
              onClick={() => setActiveTab(tab.value)}
            >
              <div className="flex items-center gap-1">
                {capitalize(tab.label)}
              </div>
            </Tab>
          ))}
        </Tab.List>
      </div>
      <BookmarksList activeTab={activeTab} />
    </Tab.Group>
  );
};
