import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";
import type { InfiniteData } from "react-query";
import { t } from "@/i18n-js/instance";
import type { NotificationPage } from "@circle-react/types/Notification";
import { NOTIFICATIONS_PER_PAGE } from "./constants";
import { useFetchNotifications } from "./useHeaderNotificationsApi";

export const TABS = {
  INBOX: "inbox",
  MENTIONS: "mentions",
  FOLLOWING: "following",
  ALL: "all",
  ARCHIVED: "archived",
} as const;

export type Tab = (typeof TABS)[keyof typeof TABS];

const TAB_LABELS = {
  [TABS.INBOX]: t("notifications.group_tabs.inbox"),
  [TABS.MENTIONS]: t("notifications.group_tabs.mentions"),
  [TABS.FOLLOWING]: t("notifications.group_tabs.following"),
  [TABS.ALL]: t("notifications.group_tabs.all"),
  [TABS.ARCHIVED]: t("notifications.group_tabs.archived"),
} as const;

export interface NotificationContextProps {
  activeTab: Tab;
  setActiveTab: (tab: Tab) => void;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  notifications?: InfiniteData<NotificationPage>;
  refetch: () => Promise<unknown>;
  hasNextPage: boolean;
  fetchNextPage: () => Promise<unknown>;
  notificationCount: number;
  setNotificationCount: (count: number) => void;
  getTabLabel: (tab: Tab) => string;
}

const NotificationContext = createContext<NotificationContextProps | null>(
  null,
);

NotificationContext.displayName = "NotificationContext";

export interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider = ({
  children,
}: NotificationProviderProps) => {
  const getTabLabel = (tab: Tab) => TAB_LABELS[tab];

  const [activeTab, setActiveTab] = useState<Tab>(TABS.INBOX);
  const [notificationCount, setNotificationCount] = useState(0);

  const {
    isLoading,
    notifications,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useFetchNotifications({
    perPage: NOTIFICATIONS_PER_PAGE,
    notificationGroup: activeTab.toLocaleLowerCase(),
  });

  return (
    <NotificationContext.Provider
      value={{
        activeTab,
        setActiveTab,
        isLoading,
        isFetchingNextPage,
        notifications,
        refetch,
        hasNextPage,
        fetchNextPage,
        notificationCount,
        setNotificationCount,
        getTabLabel,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      "useNotificationContext must be used within a NotificationProvider",
    );
  }

  return context;
};
