import { useLocation } from "react-router-dom";
import { SETTINGS_ROUTES } from "@circle-react/components/SettingsApp/Routes/constants";
import { workflowsSettingsPaths } from "@circle-react/helpers/urlHelpers";

const WORKFLOW_EDITOR_ROUTES = [
  workflowsSettingsPaths.new(),
  workflowsSettingsPaths.edit({ workflowId: "" }),
  workflowsSettingsPaths.view({ workflowId: "" }),
];

export const useSettingsHeader = () => {
  const { pathname } = useLocation();

  const isSettingsRoute = SETTINGS_ROUTES.some(route =>
    pathname.startsWith(route),
  );

  const isWorkflowsEditorRoute = WORKFLOW_EDITOR_ROUTES.some(route =>
    pathname.startsWith(route),
  );

  return { isSettingsRoute, isReactFlowWorkflowsRoute: isWorkflowsEditorRoute };
};
