import type { ReactNode } from "react";
import classNames from "classnames";
import { HeadlineTitle } from "./HeadlineTitle";

export interface ContainerProps {
  children: ReactNode;
  className?: string;
}

const Container = ({ children, className }: ContainerProps) => (
  <div className={classNames("px-6 pb-1 pt-5 lg:px-9", className)}>
    <div className="flex min-h-[1.75rem] items-center gap-4">{children}</div>
  </div>
);

export const Headline = Object.assign(Container, {
  Title: HeadlineTitle,
});
