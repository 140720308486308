import { useEffect } from "react";
import { t } from "@/i18n-js/instance";
import { BotChatList } from "@circle-react/components/Modals/MessagingModal/LeftSidebar/List/BotChatList";
import { useShouldShowAgentsTab } from "@circle-react/components/SettingsApp/CommunityInbox/hooks/useShouldShowAgentsTab";
import { CounterBadge } from "@circle-react/components/shared/CounterBadge";
import { roundedCount } from "@circle-react/helpers/notificationHelpers";
import {
  useChatUIStore,
  useCommunityInboxStore,
} from "@circle-react/hooks/chatsV2/store";
import { useChatNotification } from "@circle-react/hooks/chatsV2/useChatNotification";
import { useShouldShowMessagingRoutes } from "@circle-react/hooks/useIsMessagingEnabled";
import { Icon } from "@circle-react-shared/Icon";
import { Tab } from "@circle-react-uikit/TabV2";
import { DMsList } from "./List";
import { ListWrapper } from "./List/ListWrapper";
import { NoUnreadMessages } from "./NoUnreadMessages";

interface TabHeaderProps {
  className?: string;
}

export const TabHeader = ({ className = "" }: TabHeaderProps) => {
  const { unreadChatRoomUuids = [] } = useChatNotification();
  const unreadChatRoomsCount = unreadChatRoomUuids.length;
  const {
    totalUnreadChatsCount: totalUnreadBotChatsCount,
    fetchTotalUnreadChatsCount,
  } = useCommunityInboxStore();

  const { showMessagesTab: shouldShowMessagesTab } =
    useShouldShowMessagingRoutes();
  const shouldShowAgentsTab = useShouldShowAgentsTab();
  const { dmSelectedTabIndex, setDmSelectedTabIndex } = useChatUIStore();

  useEffect(() => {
    if (shouldShowAgentsTab) {
      void fetchTotalUnreadChatsCount();
    }
  }, [shouldShowAgentsTab, fetchTotalUnreadChatsCount]);

  const renderNotificationsCount = (): JSX.Element => (
    <CounterBadge count={roundedCount(unreadChatRoomsCount)} />
  );

  const renderCommunityInboxCount = (): JSX.Element => (
    <CounterBadge count={roundedCount(totalUnreadBotChatsCount)} />
  );

  return (
    <Tab.Group
      className="bg-primary overflow-hidden"
      selectedIndex={dmSelectedTabIndex}
      onChange={setDmSelectedTabIndex}
    >
      <div className="border-primary border-b px-6">
        <Tab.List>
          {shouldShowMessagesTab && (
            <Tab className={className}>{t("messaging.inbox")}</Tab>
          )}
          {shouldShowMessagesTab && (
            <Tab className={className}>
              <div className="flex items-center gap-1.5">
                {t("messaging.unread")}
                {renderNotificationsCount()}
              </div>
            </Tab>
          )}
          {shouldShowAgentsTab && (
            <Tab>
              <div className="flex items-center gap-1.5 text-sm">
                <Icon type="16-stars-ai" size={16} />
                {t("messaging.agents")}
                {renderCommunityInboxCount()}
              </div>
            </Tab>
          )}
        </Tab.List>
      </div>
      <Tab.Panels>
        {shouldShowMessagesTab && (
          <Tab.Panel>
            <DMsList />
          </Tab.Panel>
        )}
        {shouldShowMessagesTab && (
          <Tab.Panel>
            {unreadChatRoomsCount === 0 ? (
              <ListWrapper>
                <NoUnreadMessages />
              </ListWrapper>
            ) : (
              <DMsList unreadChatRoomUuids={unreadChatRoomUuids} />
            )}
          </Tab.Panel>
        )}
        {shouldShowAgentsTab && (
          <Tab.Panel>
            <ListWrapper>
              <BotChatList isRightActionBlock />
            </ListWrapper>
          </Tab.Panel>
        )}
      </Tab.Panels>
    </Tab.Group>
  );
};
