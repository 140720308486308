import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";
import { newChatRoomPath } from "@circle-react/helpers/urlHelpers";
import { useShouldShowNewMessagingButton } from "@circle-react/hooks/useIsMessagingEnabled";
import { Typography } from "@circle-react-uikit/Typography";
import { IconButton } from "../../IconButton";

export const Header = () => {
  const shouldShowNewMessagingButton = useShouldShowNewMessagingButton();
  const history = useHistory();

  return (
    <div className="flex items-center justify-between px-6 pb-2 pt-5">
      <Typography.LabelLg weight="medium">
        {t("header_v3.direct_messages")}
      </Typography.LabelLg>
      {shouldShowNewMessagingButton && (
        <TippyV2 interactive={false} content={t("new_message")}>
          <IconButton
            name="20-add-lg"
            iconSize={20}
            ariaLabel={t("new_message")}
            onClick={() => history.push(newChatRoomPath())}
            dataTestId="new-message"
            iconClassName="!text-dark"
          />
        </TippyV2>
      )}
    </div>
  );
};
