import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import type { IconType } from "@circle-react-shared/Icon/IconNames";
import { Typography } from "../Typography/index";

export interface ChipProps {
  icon?: IconType;
  iconSize?: number;
  title: string;
  variant?: "primary" | "secondary" | "tertiary";
  url?: string;
  onClick?: () => void;
  isButton?: boolean;
  dataTestId?: string;
}

export const Chip = ({
  icon,
  iconSize,
  title,
  variant = "primary",
  url,
  onClick,
  isButton = false,
  dataTestId = "event-chip",
}: ChipProps) => {
  const isPrimary = variant === "primary";
  const isSecondary = variant === "secondary";
  const isTertiary = variant === "tertiary";

  const HtmlElement = isButton ? "button" : "div";
  return (
    <HtmlElement
      className={classNames(
        "flex h-8 w-fit max-w-[228px] items-center space-x-2 rounded-2xl px-4 py-2",
        {
          "bg-tertiary text-default": isPrimary,
          "bg-chip text-chip": isSecondary,
          "bg-primary border-primary text-very-dark border": isTertiary,
        },
      )}
      onClick={() => {
        if (url) window.open(url, "_blank", "noreferrer");
        else onClick?.();
      }}
      data-testid={dataTestId}
    >
      {icon && (
        <Icon
          type={icon}
          size={iconSize || 16}
          className="!h-4 !w-4 text-inherit"
        />
      )}
      <Typography.LabelXs
        weight="semibold"
        color="text-inherit"
        truncate
        data-testid="chip-title"
      >
        {title}
      </Typography.LabelXs>
    </HtmlElement>
  );
};
