import * as PropTypes from "prop-types";
import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import "./styles.scss";

export const Checkbox = ({
  id,
  variant,
  name,
  label = t("checkbox"),
  ...passThroughProps
}) => {
  const { register } = useFormContext();

  if (variant === "box") {
    return <BoxSelect name={name} {...passThroughProps} />;
  }

  const containerClassname = classNames("react-checkbox", {
    "simple-toggle": variant === "switch",
  });
  return (
    <div className={containerClassname}>
      <input
        {...passThroughProps}
        type="checkbox"
        id={id}
        {...register(name)}
      />
      <label htmlFor={id}>
        <span className="sr-only">{label}</span>
      </label>
    </div>
  );
};

const BoxSelectComponent = ({ value = [], onChange, direction, list = [] }) => {
  const singleValues = list.filter(item => !item.multi).map(item => item.name);

  const handleClick = (itemName, multi, event) => {
    event.preventDefault();
    let newValue = value;
    if (value.includes(itemName)) {
      if (multi && value.length > 1) {
        newValue = value.filter(v => v !== itemName);
      }
    } else {
      if (multi) {
        newValue = [
          ...value.filter(item => !singleValues.includes(item)),
          itemName,
        ];
      } else {
        newValue = [itemName];
      }
    }
    onChange && onChange(newValue);
  };

  const Box = ({ multi, label, name, description, icon }) => {
    const isSelected = value.includes(name);

    return (
      <div
        className={classNames("react-checkbox__box", {
          "react-checkbox__box--selected": isSelected,
        })}
      >
        <button
          type="submit"
          name={name}
          onClick={event => handleClick(name, multi, event)}
        >
          {label}
        </button>
        {icon && (
          <span className="react-checkbox__box-icon">
            <Icon
              type={isSelected ? icon.selected : icon.default}
              viewBox={icon.viewBox}
            />
          </span>
        )}
        <span className="react-checkbox__box-label">{label}</span>
        <span className="react-checkbox__box-description">{description}</span>
      </div>
    );
  };

  const boxList = list.map(item => <Box {...item} key={item.name} />);

  return (
    <div className={classNames("react-checkbox__boxes", direction)}>
      {boxList}
    </div>
  );
};

const BoxSelect = ({ name, ...passThroughProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => (
        <BoxSelectComponent
          {...passThroughProps}
          name={name}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["switch", "box"]),
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
};
