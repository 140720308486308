import { t } from "@/i18n-js/instance";

export interface Shortcut {
  name: string;
  keys: string[];
  permissions?: string[];
}

export interface ShortcutCategory {
  category: string;
  shortcuts: Shortcut[];
}

export const KEYBOARD_SHORTCUTS_DATA: ShortcutCategory[] = [
  {
    category: t("header_v3.shortcuts.category.basics"),
    shortcuts: [
      {
        name: t("header_v3.shortcuts.name.open_search"),
        keys: ["cmd", "K"],
      },
      {
        name: t("header_v3.shortcuts.name.open_keyboard_shortcuts"),
        keys: ["cmd", "/"],
      },
      {
        name: t("header_v3.shortcuts.name.toggle_light_dark_mode"),
        keys: ["cmd", "alt", "u"],
      },
      {
        name: t("header_v3.shortcuts.name.open_notifications"),
        keys: ["alt", "n"],
      },
      {
        name: t("header_v3.shortcuts.name.open_direct_messages"),
        keys: ["alt", "m"],
      },
      {
        name: t("header_v3.shortcuts.name.open_chat_threads"),
        keys: ["alt", "t"],
      },
      {
        name: t("header_v3.shortcuts.name.open_bookmarks"),
        keys: ["alt", "b"],
      },
      {
        name: t("header_v3.shortcuts.name.customize_theme"),
        keys: ["alt", "c", "t"],
        permissions: ["admin"],
      },
      {
        name: t("header_v3.shortcuts.name.customize_notifications"),
        keys: ["alt", "c", "n"],
      },
      {
        name: t("header_v3.shortcuts.name.edit_profile"),
        keys: ["alt", "g", "p"],
      },
      {
        name: t("header_v3.shortcuts.name.view_profile"),
        keys: ["alt", "g", "v"],
      },
      {
        name: t("header_v3.shortcuts.name.write_a_post"),
        keys: ["cmd", "shift", "p"],
      },
    ],
  },
  {
    category: t("header_v3.shortcuts.category.community_switching"),
    shortcuts: [
      {
        name: t("header_v3.shortcuts.name.a_specific_community"),
        keys: ["alt", "g", "1-9"],
      },
    ],
  },
  {
    category: t("header_v3.shortcuts.category.navigation"),
    shortcuts: [
      {
        name: t("header_v3.shortcuts.name.add_space"),
        keys: ["alt", "shift", "n"],
        permissions: ["admin", "moderator"],
      },
      {
        name: t("header_v3.shortcuts.name.add_space_group"),
        keys: ["alt", "shift", "g"],
        permissions: ["admin"],
      },
      {
        name: t("header_v3.shortcuts.name.home_feed"),
        keys: ["alt", "g", "h"],
      },
      {
        name: t("header_v3.shortcuts.name.go_live"),
        keys: ["alt", "g", "l"],
        permissions: ["admin"],
      },
      {
        name: t("header_v3.shortcuts.name.member_directory"),
        keys: ["alt", "g", "m"],
        permissions: ["admin"],
      },
      {
        name: t("header_v3.shortcuts.name.course_directory"),
        keys: ["alt", "g", "c"],
      },
      {
        name: t("header_v3.shortcuts.name.settings"),
        keys: ["alt", "g", "s"],
        permissions: ["admin"],
      },
      {
        name: t("header_v3.shortcuts.name.event_directory"),
        keys: ["alt", "g", "e"],
      },
    ],
  },
  {
    category: t("header_v3.shortcuts.category.text_editor"),
    shortcuts: [
      {
        name: t("header_v3.shortcuts.name.slash_commands"),
        keys: ["/"],
      },
      {
        name: t("header_v3.shortcuts.name.emoji_autocomplete"),
        keys: [":"],
      },
      {
        name: t("header_v3.shortcuts.name.mentions_autocomplete"),
        keys: ["@"],
      },
      {
        name: t("header_v3.shortcuts.name.copy"),
        keys: ["cmd", "C"],
      },
      {
        name: t("header_v3.shortcuts.name.cut"),
        keys: ["cmd", "X"],
      },
      {
        name: t("header_v3.shortcuts.name.paste"),
        keys: ["cmd", "V"],
      },
      {
        name: t("header_v3.shortcuts.name.paste_without_formatting"),
        keys: ["cmd", "shift", "V"],
      },
      {
        name: t("header_v3.shortcuts.name.undo"),
        keys: ["cmd", "Z"],
      },
      {
        name: t("header_v3.shortcuts.name.redo"),
        keys: ["cmd", "shift", "Z"],
      },
      {
        name: t("header_v3.shortcuts.name.add_a_line_break"),
        keys: ["shift", "enter"],
      },
      {
        name: t("header_v3.shortcuts.name.bold"),
        keys: ["cmd", "B"],
      },
      {
        name: t("header_v3.shortcuts.name.italic"),
        keys: ["cmd", "I"],
      },
      {
        name: t("header_v3.shortcuts.name.underline"),
        keys: ["cmd", "U"],
      },
      {
        name: t("header_v3.shortcuts.name.strikethrough"),
        keys: ["cmd", "shift", "X"],
      },
    ],
  },
];
