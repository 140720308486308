import { useCallback, useEffect, useMemo, useState } from "react";
import { AccordionItem } from "./AccordionItem";
import { AccordionContext } from "./useAccordionContext";
import "./styles.scss";

interface AccordionProps {
  children: any;
  defaultActiveItemTitle?: string;
  defaultOpen?: boolean;
}

const AccordionComponent = ({
  children,
  defaultActiveItemTitle,
  defaultOpen,
}: AccordionProps) => {
  const [activeItemTitle, setActiveItemTitle] = useState(
    children?.[0]?.props?.title || "",
  );
  const [activeItemTitles, setActiveItemTitles] = useState<string[]>([]);

  useEffect(() => {
    if (defaultActiveItemTitle) {
      setActiveItemTitle(defaultActiveItemTitle);
    }
  }, [defaultActiveItemTitle]);

  const setDefaultOpenToggle = useCallback(
    (title: string) => {
      setActiveItemTitles(previous =>
        previous.includes(title)
          ? previous.filter(previousTitle => previousTitle !== title)
          : [...previous, title],
      );
    },
    [setActiveItemTitles],
  );

  const setToggle = useCallback(
    title => {
      setActiveItemTitle(() => {
        if (activeItemTitle !== title) return title;
        return "";
      });
    },
    [setActiveItemTitle, activeItemTitle],
  );

  const checkIfActive = useCallback(
    (title: string) =>
      defaultOpen
        ? !activeItemTitles.includes(title)
        : activeItemTitle === title,
    [defaultOpen, activeItemTitles, activeItemTitle],
  );

  const onToggle = useCallback(
    (title: string) => {
      if (defaultOpen) {
        setDefaultOpenToggle(title);
      } else {
        setToggle(title);
      }
    },
    [defaultOpen, setDefaultOpenToggle, setToggle],
  );

  const value = useMemo(
    () => ({
      onToggle,
      checkIfActive,
    }),
    [onToggle, checkIfActive],
  );

  return (
    <AccordionContext.Provider value={value}>
      <div className="accordion">{children}</div>
    </AccordionContext.Provider>
  );
};

export const Accordion = Object.assign(AccordionComponent, {
  Item: AccordionItem,
});
