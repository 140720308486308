import { formatNotificationsCount } from "./utils";

interface BadgeIndicatorProps {
  count: number;
  dataTestId?: string;
}

export const BadgeIndicator = ({ count, dataTestId }: BadgeIndicatorProps) => (
  <div
    className="pointer-events-none absolute right-0.5 top-0.5 z-10"
    data-testid={dataTestId}
  >
    <span className="bg-c-sidebar-badge text-c-sidebar-badge-text absolute right-0 top-0 inline-flex rounded-full">
      <span className="inline-block min-h-[1rem] min-w-[1rem] px-1 text-center text-[0.625rem] font-semibold leading-4">
        {formatNotificationsCount(count)}
      </span>
    </span>
  </div>
);
