import classNames from "classnames";

export interface MinimalProgressBarProps {
  size?: "sm" | "md";
  progress?: number;
  type?: "success" | "danger";
}

export const MinimalProgressBar = ({
  type = "success",
  size = "sm",
  progress = 0,
}: MinimalProgressBarProps) => {
  let progressValue = progress;

  if (progress > 100) {
    progressValue = 100;
  }

  if (progress < 0) {
    progressValue = 0;
  }

  return (
    // eslint-disable-next-line jsx-a11y/prefer-tag-over-role -- We have custom styles for this element
    <div
      role="progressbar"
      className={classNames(
        "w-full rounded-full bg-gray-200 dark:bg-gray-600",
        {
          "h-1": size === "sm",
          "h-2": size === "md",
        },
      )}
      aria-valuenow={progressValue}
    >
      <div
        className={classNames("rounded-full", {
          "h-1": size === "sm",
          "h-2": size === "md",
          "bg-v2-success": type == "success",
          "bg-v2-danger": type == "danger",
        })}
        style={{ width: `${progressValue}%` }}
      />
    </div>
  );
};
