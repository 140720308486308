import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { memberProfileModalPaths } from "@/react/helpers/urlHelpers";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-uikit/Typography";
import { IconButton } from "../../IconButton";
import { NOTIFICATION_ACTION_MAPPER } from "./List/Item/constants";
import { useNotificationsActions } from "./useHeaderNotificationsApi";

export const Header = () => {
  const history = useHistory();

  const {
    mutate: performNotificationAction,
    isLoading: areNotificationsBeingMarkedAsRead,
  } = useNotificationsActions();

  return (
    <div className="flex items-center justify-between px-6 pb-2 pt-5">
      <Typography.LabelLg weight="medium">
        {t("header_v3.notifications")}
      </Typography.LabelLg>
      <div className="flex items-center space-x-2">
        <TippyV2 interactive={false} content={t("header_v3.mark_all_as_read")}>
          <IconButton
            name="16-mark-as-read"
            onClick={() =>
              performNotificationAction({
                action: NOTIFICATION_ACTION_MAPPER.markAllAsRead,
              })
            }
            ariaLabel={t("header_v3.mark_all_as_read")}
            disabled={areNotificationsBeingMarkedAsRead}
          />
        </TippyV2>
        <TippyV2
          interactive={false}
          content={t("header_v3.notification_settings")}
        >
          <IconButton
            name="16-settings"
            ariaLabel={t("header_v3.notification_settings")}
            onClick={() => history.push(memberProfileModalPaths.notifications)}
          />
        </TippyV2>
      </div>
    </div>
  );
};
