import PropTypes from "prop-types";
import classNames from "classnames";
import { MOBILE_BOTTOM_TOOLBAR_ID } from "@circle-react/components/constants";

const isMobileBottomToolbarVisible = () =>
  Boolean(document.getElementById(MOBILE_BOTTOM_TOOLBAR_ID));

export const ListWrapper = ({ children, className }) => (
  <div
    className={classNames(
      "w-full space-y-0.5 overflow-y-auto px-2 lg:h-[calc(100%-113px)]",
      {
        "h-[calc(100dvh-169px)]": !isMobileBottomToolbarVisible(), //App header (64px) + DMs header (64px) + tab header (41px)
        "h-[calc(100dvh-232px)]": isMobileBottomToolbarVisible(), //App header (64px) + DMs header (64px) + app bottom bar (64px) + tab header (40px)
      },
      className,
    )}
    id="scrollable-dms-div"
  >
    {children}
  </div>
);

ListWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isSmallScreen: PropTypes.bool,
};
