import type { ReactNode } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { useHeaderBorderStyles } from "./useHeaderColors";

export interface HeaderWrapperProps {
  children: ReactNode;
  className?: string;
  isRenderedOutsideSpace?: boolean;
}

export const HeaderWrapper = ({
  children,
  className,
  isRenderedOutsideSpace = false,
}: HeaderWrapperProps) => {
  const styles = useHeaderBorderStyles();
  return (
    <nav
      data-testid="navigation-bar-wrapper"
      aria-label={t("search_and_notifications")}
      className={classNames(
        "border-primary bg-c-header grid-cols-header sticky top-0 z-50 grid min-h-[48px] border-b",
        {
          "max-h-12 py-2 pl-4 pr-6": !isRenderedOutsideSpace,
          "sticky top-0 z-50 max-h-16 px-6 py-4": isRenderedOutsideSpace,
        },
        className,
      )}
      id="root-header-v2_1"
      style={styles}
    >
      {children}
    </nav>
  );
};
