import { Switch } from "@headlessui/react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Checkmark } from "./Checkmark";
import { STATES as CONTAINER_STATES, Container } from "./Container";
import { Label } from "./Label";
import { Loader } from "./Loader";

interface ToggleWithLoaderProps {
  isChecked: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  onChange: (checked: boolean) => void;
  checkedLabel?: string;
  uncheckedLabel?: string;
  tooltipCheckedLabel?: string;
  tooltipUncheckedLabel?: string;
  className?: string;
  containerClassName?: string;
  tooltipPlacement?: string;
}

export const ToggleWithLoader = ({
  isChecked,
  isLoading = false,
  isDisabled = false,
  onChange,
  checkedLabel,
  uncheckedLabel,
  tooltipCheckedLabel,
  tooltipUncheckedLabel,
  className,
  containerClassName,
  tooltipPlacement,
  ...rest
}: ToggleWithLoaderProps) => {
  const getContainerState = () => {
    if (isLoading) return CONTAINER_STATES.LOADING;
    return isChecked ? CONTAINER_STATES.CHECKED : CONTAINER_STATES.UNCHECKED;
  };

  return (
    <Switch
      checked={isChecked}
      onChange={onChange}
      className={classNames("flex", containerClassName)}
      disabled={isDisabled || isLoading}
      {...rest}
    >
      {({ checked: isChecked }) => (
        <Container
          state={getContainerState()}
          isDisabled={isDisabled}
          tooltipLabelChecked={tooltipCheckedLabel}
          tooltipLabelUnchecked={tooltipUncheckedLabel}
          placement={tooltipPlacement}
          className={classNames(className, {
            "cursor-not-allowed opacity-50": isDisabled,
          })}
        >
          <span className="sr-only">
            {isChecked ? checkedLabel : uncheckedLabel}
          </span>

          <span
            className={classNames("left-0 flex w-full px-2", {
              "justify-start": isChecked,
              "justify-end": !isChecked,
              "opacity-0": isLoading,
              "ml-6": !isChecked && !isLoading,
              "mr-6": isChecked && !isLoading,
            })}
          >
            {Boolean(isChecked && !isLoading && checkedLabel) && (
              <Label className="">{checkedLabel}</Label>
            )}
            {Boolean(!isChecked && !isLoading && uncheckedLabel) && (
              <Label className="">{uncheckedLabel}</Label>
            )}
          </span>

          {!isLoading && <Checkmark isChecked={isChecked} />}

          {isLoading && <Loader />}
        </Container>
      )}
    </Switch>
  );
};
