import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useCurrentCommunityMember } from "@circle-react/contexts";
import { usePunditUserContext } from "@circle-react/contexts";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

export const Banner = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  const isGranularDmSettingsEnabled =
    !!currentCommunitySettings?.granular_dm_settings_enabled;
  const currentCommunityMember = useCurrentCommunityMember();
  const history = useHistory();

  const isMessagingEnabled =
    currentCommunityMember?.messaging_enabled_by_member &&
    currentCommunityMember?.messaging_enabled_by_admin;

  if (!isGranularDmSettingsEnabled || isMessagingEnabled) {
    return null;
  }

  const openEditProfile = () => {
    history.push("/account");
  };

  const isAdmin = currentCommunityMember?.is_admin;
  return (
    <div className="bg-tertiary flex h-[81px] w-full items-center justify-between rounded-t-md px-4 py-3">
      <div className="mr-3 flex items-center gap-3">
        <div className="w-5">
          <Icon type="20-info-circle" className="text-dark" size={20} />
        </div>
        <Typography.BodyXs>
          {isAdmin
            ? t("messaging.banner.admin_messaging_disabled")
            : t("messaging.banner.member_messaging_disabled")}
        </Typography.BodyXs>
      </div>
      <Button
        variant="plain"
        onClick={openEditProfile}
        className="!text-dark !font-medium focus-within:!outline-none"
      >
        {t("messaging.banner.manage")}
      </Button>
    </div>
  );
};
