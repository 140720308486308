import { t } from "@/i18n-js/instance";
import type { Notification } from "@circle-react/types/Notification";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { IconButton } from "@circle-react-uikit/HeaderV3/IconButton";
import { useNotificationsActions } from "../../useHeaderNotificationsApi";
import {
  FOLLOWABLE_NOTIFICATION_TYPES,
  NOTIFICATION_ACTION_MAPPER,
  NOTIFICATION_ARCHIVED_STATUS,
} from "./constants";

export interface ActionsProps {
  notification: Notification;
}

export const Actions = ({ notification }: ActionsProps) => {
  const canUnfollowPost = notification.post_follower_id;
  const canFollowPost =
    FOLLOWABLE_NOTIFICATION_TYPES.includes(notification.notifiable_type) &&
    !notification.post_follower_id;
  const canArchiveNotification =
    notification.notification_group !== NOTIFICATION_ARCHIVED_STATUS;
  const hasNotificationBeenRead = notification.read_at;

  const {
    mutate: performNotificationAction,
    isLoading: isNotificationActionBeingPerformed,
  } = useNotificationsActions();

  const isLiveAction = notification.action === "live_stream_went_live";
  const shouldSkipNotificationDropdown =
    !canUnfollowPost &&
    !canArchiveNotification &&
    hasNotificationBeenRead !== null &&
    isLiveAction;

  if (shouldSkipNotificationDropdown) {
    return null;
  }

  return (
    <Dropdown
      size="medium"
      direction="bottom-end"
      className="hidden h-6 w-6 group-hover:inline-block"
      button={
        <IconButton name="16-menu-dots-horizontal" ariaLabel={t("actions")} />
      }
      transitionWrapperClassName="z-30"
    >
      {!hasNotificationBeenRead && (
        <Dropdown.ItemLink
          onClick={(e: any) => {
            performNotificationAction({
              id: notification.id,
              action: NOTIFICATION_ACTION_MAPPER.markAsRead,
            });
            e.stopPropagation();
          }}
          disabled={isNotificationActionBeingPerformed}
        >
          {t("notifications.actions.mark_as_read")}
        </Dropdown.ItemLink>
      )}
      {canUnfollowPost && (
        <Dropdown.ItemLink
          onClick={(e: any) => {
            performNotificationAction({
              postId: notification.post_id,
              followerId: notification.post_follower_id,
              action: NOTIFICATION_ACTION_MAPPER.unfollow,
            });
            e.stopPropagation();
          }}
          disabled={isNotificationActionBeingPerformed}
        >
          {t("notifications.actions.unfollow_post")}
        </Dropdown.ItemLink>
      )}
      {canFollowPost && (
        <Dropdown.ItemLink
          onClick={(e: any) => {
            performNotificationAction({
              postId: notification.post_id,
              action: NOTIFICATION_ACTION_MAPPER.follow,
            });
            e.stopPropagation();
          }}
          disabled={isNotificationActionBeingPerformed}
        >
          {t("notifications.actions.follow_post")}
        </Dropdown.ItemLink>
      )}
      {canArchiveNotification && (
        <Dropdown.ItemLink
          onClick={(e: any) => {
            performNotificationAction({
              id: notification.id,
              action: NOTIFICATION_ACTION_MAPPER.archive,
            });
            e.stopPropagation();
          }}
          disabled={isNotificationActionBeingPerformed}
        >
          {t("notifications.actions.archive")}
        </Dropdown.ItemLink>
      )}
    </Dropdown>
  );
};
