import { ItemLink } from "@circle-react-uikit/Dropdown/ItemLink";
import { ItemWithLink } from "@circle-react-uikit/Dropdown/ItemWithLink";
import { Separator } from "@circle-react-uikit/Dropdown/Separator";
import { ThreeDotsDropdown } from "./ThreeDotsDropdown";

export const Actions = Object.assign(ThreeDotsDropdown, {
  Item: ItemWithLink,
  ItemLink,
  Separator,
});
