import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Typography } from "@circle-react-uikit/Typography";

export const Label = ({
  className,
  children,
}: {
  className: string;
  children: React.ReactNode;
}) => (
  <span
    className={classNames(
      "flex h-4 items-center justify-center leading-none transition-opacity duration-200 ease-in-out",
      className,
    )}
  >
    <Typography.LabelXs weight="semibold">{children}</Typography.LabelXs>
  </span>
);
