import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { Table } from "@circle-react-uikit/TableV2";
import { Typography } from "@circle-react-uikit/Typography";
import { useDataTableDataProvider } from "../Provider/DataTableDataProvider";

export const NoRecords = ({ text }) => {
  const { data, columns, isLoading } = useDataTableDataProvider();
  const colSpan = columns.length;
  const hasContent = data.length > 0;

  if (!hasContent && !isLoading) {
    return (
      <Table.TableBody>
        <Table.Tr>
          <Table.Td colSpan={colSpan}>
            <div className="text-center">
              <Typography.LabelSm color="text-light">
                {text || t("datatable.empty.no_data")}
              </Typography.LabelSm>
            </div>
          </Table.Td>
        </Table.Tr>
      </Table.TableBody>
    );
  }

  return null;
};

NoRecords.propTypes = {
  text: PropTypes.string,
};
