import PropTypes from "prop-types";
import classnames from "classnames";
import { noop } from "lodash";
import I18n from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";
import { Portal } from "@circle-react-uikit/Portal";
import "./styles.scss";

export const Confirm = ({
  onClose,
  show = false,
  onConfirm = noop,
  onCancel,
  title,
  subtitle,
  sentiment = "danger",
  loading = false,
  description = "",
  bodyElement = null,
  maxZIndexEnabled = false,
  buttonLabel = "",
}) => {
  if (!show) return null;
  return (
    <Portal className="z-50">
      <div className={classnames("confirm", { "z-2000": maxZIndexEnabled })}>
        <div className="confirm__container">
          <h4 className="confirm__title">{title}</h4>
          {subtitle && <h5 className="confirm__subtitle">{subtitle}</h5>}
          {description && <p className="pt-6 text-center">{description}</p>}
          {bodyElement}
          <div className="confirm__actions">
            <Button variant="secondary" onClick={onCancel || onClose}>
              {I18n.t("cancel")}
            </Button>
            <Button
              disabled={loading}
              variant={sentiment}
              strong
              onClick={onConfirm}
            >
              {buttonLabel || I18n.t("confirm")}
            </Button>
          </div>
        </div>
      </div>
    </Portal>
  );
};

Confirm.propTypes = {
  description: PropTypes.string,
  maxZIndexEnabled: PropTypes.bool,
  bodyElement: PropTypes.func,
  buttonLabel: PropTypes.string,
};
